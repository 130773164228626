/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */

/* Core CSS required for Ionic components to work properly */
@import "~@ionic/angular/css/core.css";

/* Basic CSS for apps built with Ionic */
@import "~@ionic/angular/css/normalize.css";
@import "~@ionic/angular/css/structure.css";
@import "~@ionic/angular/css/typography.css";
@import "~@ionic/angular/css/display.css";

/* Optional CSS utils that can be commented out */
@import "~@ionic/angular/css/padding.css";
@import "~@ionic/angular/css/float-elements.css";
@import "~@ionic/angular/css/text-alignment.css";
@import "~@ionic/angular/css/text-transformation.css";
@import "~@ionic/angular/css/flex-utils.css";

@font-face {
  font-family: "Cera GR Light";
  font-style: normal;
  font-weight: normal;
  src: url("./assets/fonts/Cera\ GR\ Light.otf");
}

@font-face {
  font-family: "Cera GR Medium";
  font-style: normal;
  font-weight: normal;
  src: url("./assets/fonts/Cera\ GR\ Medium.otf");
}

@font-face {
  font-family: "Cera GR Bold";
  font-style: normal;
  font-weight: normal;
  src: url("./assets/fonts/Cera\ GR\ Bold.otf");
}

@font-face {
  font-family: "Proxima Nova";
  font-style: normal;
  font-weight: normal;
  src: url("./assets/fonts/Proxima\ Nova.otf");
}

@font-face {
  font-family: "Proxima Nova Bold";
  font-style: normal;
  font-weight: bold;
  src: url("./assets/fonts/Proxima\ Nova\ Bold.otf");
}

@font-face {
  font-family: "Typold Regular";
  font-style: normal;
  font-weight: normal;
  src: url("./assets/fonts/Typold\ Regular.otf");
}

@font-face {
  font-family: "Typold Medium";
  font-style: normal;
  font-weight: normal;
  src: url("./assets/fonts/Typold\ Medium.otf");
}

@font-face {
  font-family: "Typold Extra Bold";
  font-style: normal;
  font-weight: normal;
  src: url("./assets/fonts/Typold\ Extra\ Bold.otf");
}

@font-face {
  font-family: "Montserrat Thin";
  font-style: normal;
  font-weight: normal;
  src: url("./assets/fonts/Montserrat-Thin.ttf");
}

@font-face {
  font-family: "Montserrat Regular";
  font-style: normal;
  font-weight: normal;
  src: url("./assets/fonts/Montserrat-Regular.ttf");
}

@font-face {
  font-family: "Montserrat Medium";
  font-style: normal;
  font-weight: normal;
  src: url("./assets/fonts/Montserrat-Medium.ttf");
}

@font-face {
  font-family: "Montserrat Bold";
  font-style: normal;
  font-weight: normal;
  src: url("./assets/fonts/Montserrat-Bold.ttf");
}

@font-face {
  font-family: "Montserrat Extra Bold";
  font-style: normal;
  font-weight: normal;
  src: url("./assets/fonts/Montserrat-ExtraBold.ttf");
}

@font-face {
  font-family: "Roboto Regular";
  font-style: normal;
  font-weight: normal;
  src: url("./assets/fonts/Roboto-Regular.ttf");
}

@font-face {
  font-family: "Roboto Medium";
  font-style: normal;
  font-weight: normal;
  src: url("./assets/fonts/Roboto-Medium.ttf");
}

@font-face {
  font-family: "Roboto Bold";
  font-style: normal;
  font-weight: normal;
  src: url("./assets/fonts/Roboto-Bold.ttf");
}

@font-face {
  font-family: "Roboto Light";
  font-style: normal;
  font-weight: normal;
  src: url("./assets/fonts/Roboto-Light.ttf");
}

@font-face {
  font-family: "Open Sans Regular";
  font-style: normal;
  font-weight: 400;
  src: url("./assets/fonts/OpenSans.ttf");
}

@font-face {
  font-family: "Open Sans Bold";
  font-style: normal;
  font-weight: 800;
  src: url("./assets/fonts/OpenSans.ttf");
}

@font-face {
  font-family: "Open Sans Light";
  font-style: normal;
  font-weight: 300;
  src: url("./assets/fonts/OpenSans.ttf");
}

@font-face {
  font-family: "Open Sans Semi Bold";
  font-style: normal;
  font-weight: 600;
  src: url("./assets/fonts/OpenSans.ttf");
}

ion-content {
  --ion-background-color: #fafafa;
}

ion-button {
  font-family: "Proxima Nova";
}

ion-label {
  font-family: "Proxima Nova";
  margin-left: 8px !important;
  color: #636363;
  font-size: 12px !important;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "Proxima Nova Bold";
}

h4 {
  color: var(--arii-purple-color);
}

p {
  font-family: "Proxima Nova";
}

.gradient {
  --background: linear-gradient(90deg, rgba(94, 194, 219, 1) 0%, rgba(130, 24, 167, 1) 100%);
}

.full-cover-video {
  object-fit: cover;
}

.sub-hero {
  height: 40vh;
}

//Global Flex Stylings
.is-flex {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}

.column {
  flex-direction: column;
}

.child-col-2 {
  flex: 1 0 40%;
}

.justified-center {
  justify-content: center;
}

.space-between {
  justify-content: space-between;
}

.has-gap {
  gap: 20px;
}

.thumbnail-resource-card {
  width: auto;
  height: auto;
  max-width: 80%;
  margin: 8px;
}

.no-margin {
  margin: 0;
}

//CLIENT EXPERIENCE BUTTONS
.exp-selectionContainer {
  display: flex;
  //align-items: center; /* vertically center align items */
  justify-content: center; /* horizontally center align items */
  height: 100vh; /* set height of container to viewport height */
  background-position: center top !important;
  background-repeat: no-repeat !important;
  background-size: cover !important;
}

.exp-selectionDropdown {
  width: 200px;
  border: 1px solid #dcdcdc;
  border-radius: 30px;
  position: absolute;
  top: 10px;
  left: 80px;
  z-index: 100;
  background: #ffffffa7;
}

.exp-background {
  background-position: center top !important;
  background-repeat: no-repeat !important;
  background-size: cover !important;
  height: 100%;
}

.exp-homeButton {
  width: 60px;
  height: 60px;
  border-radius: 100%;
  line-height: 60px;
  text-align: center;
  font-size: 30px;
  background-position: center;
  background: #aaaaaa94;
  position: absolute;
  left: 10px;
  top: 10px;
  z-index: 100;
  display: flex;
  align-items: center;
  justify-content: center;
}

.exp-contactButton {
  background: #aaaaaa94;
  position: absolute;
  right: 10px;
  top: 10px;
  z-index: 100;
}

.exp-categoryButton {
  border-radius: 30px;
  display: inline-block;
  margin-left: 1%;
  margin-right: 1%;
  margin-bottom: 1%;
  vertical-align: middle;
  padding: 20px;
  cursor: pointer;
  background-position: center !important;
  background-size: cover !important;
  font-weight: bold;
  position: relative;
}

// .exp-categoryButton:after {
//     content: "";
//     display: inline-block;
//     vertical-align: middle;  /* Align inline level elements vertically */
//     height: 100%;
//   }

.exp-categoryButtonText {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  font-weight: bold;
  position: relative;
}

.twilioVideo video {
  width: 6vw;
  height: 6vw;
  object-fit: cover;
  border-radius: 20px;
  position: absolute;
  top: 10px;
  left: 10px;
  border: 2px solid white;
}

.localVideo {
  background-color: white;
}

.localVideo video {
  //position: absolute;
  //top: 0;
  //right: 0;
  width: 100%;
  height: auto;
  max-height: 30vh;
  object-fit: cover;
}

#twilioContainer {
  //background: #000;
  text-align: center;
  color: #fff;
}

.twilioVideo-expanded {
  min-height: 50px;
}

.twilioVideo-small-l {
  position: absolute;
  top: 0;
  left: 0;
  min-width: 50px;
  max-width: 30%;
  height: auto;
  min-height: 40px;
  height: 50px;
  border: 0px;
  background: none;
  border-radius: 5px;
}
.twilioVideo-small-r {
  position: absolute;
  top: 0;
  right: 0;
  min-width: 50px;
  max-width: 30%;
  height: auto;
  min-height: 40px;
  height: 50px;
  border: 0px;
  background: none;
  border-radius: 5px;
}

.twilioVideo-mid-r {
  position: absolute;
  bottom: 20;
  left: 20;
  max-width: 35%;
  height: auto;
  max-height: 80px;
  border: 0px;
  background: none;
  border: 1px solid white;
  border-radius: 25px;
}

@media only screen and (max-width: 767px) {
  #twilioContainer {
    height: 300px;
    background-color: white;
  }
  #twilioContainerSupport {
    height: 33vh;
    background-color: white;
    position: relative;
  }
  .customerDisplay {
    position: absolute;
    width: 60% !important;
    top: 0;
    left: 0;
    height: 300px !important;
  }
  .customerDisplaySupport {
    position: absolute;
    width: 60% !important;
    top: 0;
    left: 0;
    height: 100% !important;
  }

  .cxIframe {
    height: 400% !important;
  }

  .localVideo video {
    width: 40% !important;
    height: 140px;
    position: absolute;
    top: 155px !important;
    right: 0 !important;
    border-radius: 20px;
    border: 2px solid white;
  }

  .is-support-localVideo video {
    width: 70px !important;
    height: 70px;
    position: absolute;
    top: 10px !important;
    right: 8px !important;
    border-radius: 50%;
    border: 2px solid white;
  }

  .twilioVideo-small-r {
    top: 0 !important;
    right: 0 !important;
    min-width: none;
    max-width: none;
    min-height: none;
    border: 0px;
    background: none;
    width: 40% !important;
    height: 148px !important;
  }

  .twilioVideo video {
    width: 100% !important;
    height: 100%;
    position: absolute;
    top: 3px !important;
    left: 0 !important;
    // width: 100%;
    // height: 100%;
    // object-fit: cover;
    // border-radius: 20px;
    // top: 10px;
    // left: 10px;
    // border: 2px solid white;
  }
}
//END TWILIO

//END CLIENT EXPERIENCE BUTTONS

.color-phone {
  background-color: #b80056 !important;
}
.color-sms {
  background-color: #2fc669 !important;
}
.color-chat {
  background-color: #0c9be3 !important;
}
.color-kiosk {
  background-color: #af45ed !important;
}
.color-agent {
  background-color: #6e7aef !important;
}
.color-qr {
  background-color: #36bd99 !important;
}
.color-whatsapp {
  background-color: #36bd3d !important;
}
.color-dark {
  background-color: #585858 !important;
}

.overflow-auto {
  overflow: auto;
}
.mh-80 {
  max-height: 80px;
}
.mh-100 {
  max-height: 100px;
}
.mh-200 {
  max-height: 200px;
}

.min-80 {
  min-height: 80px;
}
.min-100 {
  min-height: 100px;
}
.min-200 {
  min-height: 200px;
}

.mt-5 {
  margin-top: 5px;
}

.mt-10 {
  margin-top: 10px;
}

.mt-n-10 {
  margin-top: -10px;
}

.mt-15 {
  margin-top: 15px;
}

.mt-20 {
  margin-top: 20px;
}

.mt-30 {
  margin-top: 38px;
}

.mb-0 {
  margin-bottom: 0px;
}
.mb-5 {
  margin-bottom: 5px;
}
.mb-30 {
  margin-bottom: 30px;
}
.mb-40 {
  margin-bottom: 40px;
}
.mb-50 {
  margin-bottom: 50px;
}

.ml-5 {
  margin-left: 5px;
}
.ml-10 {
  margin-left: 10px;
}
.ml-20 {
  margin-left: 20px;
}

.ml-25 {
  margin-left: 25px;
}

.mr-5 {
  margin-right: 5px;
}
.mr-10 {
  margin-right: 10px;
}

.mb-5 {
  margin-bottom: 5px;
}
.mb-10 {
  margin-bottom: 10px;
}
.mb-20 {
  margin-bottom: 20px;
}

.pb-5 {
  padding-bottom: 5px;
}
.pb-10 {
  padding-bottom: 10px;
}

.pl-5 {
  padding-left: 5px;
}
.pl-10 {
  padding-left: 10px;
}

.pl-20 {
  padding-left: 20px;
}

.pr-10 {
  padding-right: 10px;
}
.pt-5 {
  padding-top: 5px;
}

.no-border {
  border: none;
}

.display-ib {
  display: inline-block;
}

.opaque {
  opacity: 1 !important;
}

.shadow {
  box-shadow: 0px 10px 36px -14px rgba(0, 0, 0, 0.75);
  -webkit-box-shadow: 0px 10px 36px -14px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 0px 10px 36px -14px rgba(0, 0, 0, 0.75);
}

// Experience List
.dda-item {
  position: relative;
  width: 320px;
  height: 320px;
  border: 1px solid var(--arii-purple-color);
  border-radius: 20px;
}

.img-container {
  height: 163px;
  width: 318px;
  background-color: #f4f4f4;
  border-radius: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.img-container > img {
  width: 60%;
}

.archive-details {
  padding: 5px;
}

//TWILIO

.phone-overlay {
  position: absolute;
  top: 63px;
  left: 79px;
  width: 280px;
  z-index: 100;
  background: #242424e6;
  border: 2px solid #007cdc;

  border-radius: 0px 20px 20px 20px;
  color: rgb(255, 255, 255);
  text-align: center;

  box-shadow: rgba(0, 0, 0, 0.19) 0px 10px 20px, rgba(0, 0, 0, 0.23) 0px 6px 6px;
}

// .localVideo video{
//     position: absolute;
//     top: 0;
//     right: 0;
//     width: 30%;
//     height: auto;
//     max-height: 50px;
// }

#iconSidebar {
  max-width: 60px;
  line-height: 50px;
  min-width: 0px;
  flex-shrink: unset;
  //border-right: 1px solid #efefef;
}

#iconSidebar ion-button ion-icon {
  font-size: 30px;
}

#iconSidebar ion-button {
  min-height: 50px;
  margin-bottom: 10px;
}

#iconSidebar ion-button:hover {
  background: rgba(83, 194, 219, 1) 30%;
  border-radius: 15px;
}

.feature-setting {
  position: absolute;
  bottom: 0;
  right: 0;
  background-color: rgba(255, 255, 255, 0.5);
}

.bg1 {
  background-image: url("./assets/img/wallpaper.jpg");
  --background-image: url("./assets/img/wallpaper.jpg");
  background-size: cover;
}

.bg0 {
  background: #fff;
}

#progress-container {
  width: 100%;
  height: 10px;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 100;
  //border: 1px solid red;
}
#progress-bar {
  background: rgb(27, 150, 72);
  height: 5px;
}

//SITE STYLING
.resize-drag {
  width: 120px;
  padding: 0px;
  margin: 0px;
  background: #369d6680;
  touch-action: none;
  /* This makes things *much* easier */
  box-sizing: border-box;
  position: absolute;
  overflow: visible;
}

.siteContainer {
  //background-color: #333;
}

#sitePreview {
  //border: 2px solid #3295c3;
  background-size: 15px 15px;
  background-image: linear-gradient(to right, #72b6d5 1px, transparent 1px),
    linear-gradient(to bottom, #72b6d5 1px, transparent 1px);
  margin: 20px auto;
  transform-origin: 0 0;
}

.slide-template {
  border-radius: 20px;
  font-weight: bold;
  border: 1px solid #dcdcdc;
}

.rotateContainer {
  transform-origin: center center;
  border: 1px solid #88c7a3;
  background: rgba(103, 241, 241, 0.2);
}

@media screen and (max-width: 580px) {
  .template-grid {
    width: 47%;
  }
}

@media screen and (min-width: 748px) {
  .template-grid {
    width: 22%;
  }
}

//SLIDE STYLING
#slidePreview {
  background-size: 15px 15px;
  background-image: linear-gradient(to right, #72b6d5 1px, transparent 1px),
    linear-gradient(to bottom, #72b6d5 1px, transparent 1px);
  margin: 20px auto;
  transform-origin: 0 0;
}

//FOLDER STYLING
.template-grid {
  display: inline-block;
  border: 0px;
  vertical-align: middle;
  width: 18%;
  overflow: hidden;
  position: relative;
  text-align: center;
  margin: 1%;
  cursor: pointer;
  padding: 10px;
}
.template-grid:hover {
  background: #aacff0;
  border-radius: 20px;
}

.template-grid-title {
  width: 100%;
  text-align: center;
  //position: absolute;
  //height: 50px;
  //top: 50%;
  //left: 0;
  //color: #fff;
  margin-top: 5px;
  font-weight: 500;
  font-size: 18px;
  overflow: hidden;
}

.template-grid-single {
  vertical-align: middle;
  margin: 0px 0px 20px;
  overflow: hidden;
  border: 1px solid #dcdcdc;
  position: relative;
  border-radius: 10px;
  text-align: left;
}

.overlayPanelOpenTrigger {
  position: fixed;
  top: 0;
  right: 0;
  width: 60px;
  z-index: 5;
  height: 120px;
}

.overlayPanelCloseTrigger {
  position: fixed;
  top: 0;
  width: 60px;
  left: -60px;
  z-index: 5;
  height: 120px;
}

.overlayPanel {
  position: absolute;
  top: 0;
  right: 0;
  width: 65%;
  z-index: 10;
  height: 100%;
  background-color: #fff;
  border-left: 1px solid #999;
}

.inputlist {
  //background: transparent;
  border: 0px;
  padding: 3px 0px 7px;
  margin-top: 10px;
}

// textarea {
//   background-color: #efefef !important;
//   color: #222 !important;
// }

.bg {
  background: #fff !important;
}

.select-viewer {
  background-color: transparent;
  color: #333;
  font-weight: bold;
  border: 0px;

  -o-appearance: none;
  -ms-appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

/* CALENDAR OVERRIDES */
ion-calendar {
  background-color: transparent !important;
}
.month-packer-item button {
  color: #333 !important;
}

/* GLOBAL OVERRIDES */

.upload-btn-wrapper {
  position: relative;
  overflow: hidden;
  display: inline-block;
  width: 100%;
}

.upload-btn {
  width: 100%;
  height: 200px;
  border: 2px solid gray;
  color: gray;
  background-color: #e3eeff;
  padding: 8px 20px;
  border-radius: 8px;
  font-size: 20px;
  font-weight: bold;
}

.upload-btn-wrapper input[type="file"] {
  font-size: 100px;
  height: 100%;
  width: 100%;
  position: absolute;
  left: 0;
  top: 0;
  opacity: 0;
}

.picker-col {
  overflow: auto;
  min-width: 60px;
}

.picker-col::-webkit-scrollbar {
  //width: 0px; /* width of the entire scrollbar */
}

.hidden {
  visibility: hidden;
  position: absolute;
}

/* TIMELINE */
/*Timeline element*/
.timeline-item {
  border-left: 5px solid #fff;
}

.timeline-item-selected {
  border-left: 5px solid #007cdc;
  --background: #dcdcdc;
  background: #dcdcdc;
  //background-color: #007cdc;
  //color: #fff;
}
/*End timeline element*/

.patientSearch {
  position: absolute;
  left: 100%;
  width: 100%;
  top: 0;
  z-index: 5;
}

//PRINT VARIABLES

.printable-content {
  background-color: #dcdcdc;
  overflow: auto;
}

.printable-wrapper {
  transform: scale(0.7);
  width: 10in;
  transform-origin: 0 50px;
}

.printable-area {
  background-color: #fff;
  margin: 0 auto;
}

.page-break {
  page-break-after: always;
  page-break-inside: avoid;
  clear: both;
}

.page-break-before {
  page-break-before: always;
  page-break-inside: avoid;
  clear: both;
}

#html-2-pdfwrapper2 {
  position: absolute;
  left: 20px;
  top: 350px;
  bottom: 0;
  overflow: auto;
  width: 600px;
}
//END PRINT VARIABLES

//ION OVERRIDES

.input-markInput {
  width: 100%;
  border: 0px;
  background: #efefef;
  padding: 10px 5px;
}

.markinput {
  background-color: #efefef;
  margin-top: 7px;
  text-align: left;
}

.markinput-p {
  background-color: #efefef;
  margin-top: 7px;
  text-align: left;
  padding-left: 5px !important;
}

.ion-textarea {
  margin: 0px;
  border: none;
  text-align: left;
  padding: 10px;
  width: 100%;
}

//END ION OVERRIDES

/* GLOBAL OVERRIDES */

.txt-avatar {
  background-color: #4caf50;
  display: table;
}

.txt-shadow {
  text-shadow: 1px 2px 2px #555;
}

.txt-avatar-content {
  display: table-cell;
  text-transform: uppercase;
  text-align: center;
  height: 100%;
  width: 100%;
  vertical-align: middle;
}

ion-item:hover {
  --background: #dcdcdc;
  background: #dcdcdc;
  color: #000;
}

ion-item.nohover:hover {
  --background: none;
  background: none;
}

ion-item.menu-item:hover {
  --background: rgba(83, 194, 219, 0.5);
  background: rgba(83, 194, 219, 0.5);
}

.pad5 {
  padding: 5px;
}

.pad10 {
  padding: 10px;
}

.pad15 {
  padding: 15px;
}

.pad20 {
  padding: 20px;
}

.sm-show {
  display: none;
}

.hide {
  display: none;
}

/*.sc-ion-segment-md-h {
    --color-checked: #fff;
}*/

/*Override segment lower border*/
#receiptPreview {
  width: 100%;
  height: 100%;
  position: fixed;
  z-index: 100;
  left: 0;
  top: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: none;
}

#receiptPreview .receiptContent {
  top: 2.5%;
  left: 10%;
  right: 10%;
  bottom: 3%;
  position: absolute;
  z-index: 101;
  background-color: #fff;
  padding: 20px;
  padding-top: 50px;
  overflow: auto;
  border-radius: 20px;
}

.reverse {
  display: flex;
  flex-direction: column-reverse;
}

hr {
  --ion-background-color: #dcdcdc;
  background-color: #dcdcdc;
}

#iconSidebar {
  max-width: 80px;
  line-height: 50px;
  min-width: 0px;
  background-color: #1a1a1a;
  --ion-background-color: #1a1a1a;
  flex-shrink: unset;
  border-right: 1px solid #efefef;
}

#iconSidebar ion-item:hover {
  background-color: rgba(83, 194, 219, 0.4);
  --ion-background-color: rgba(83, 194, 219, 0.4);
}

#iconSidebar ion-button ion-icon {
  font-size: 30px;
}

.scroll-content {
  overflow: auto;
}

.selectable {
  -webkit-user-select: text;
  -moz-user-select: text;
  -ms-user-select: text;
  user-select: text;
}

.not-selectable {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.code {
  -webkit-user-select: text;
  -moz-user-select: text;
  -ms-user-select: text;
  user-select: text;
  background: #dcdcdc;
  --background: #dcdcdc;
  font-size: 10px;
  font-family: Courier New, Courier, monospace;
}

.bordered-container {
  border: 1px solid var(--arii-purple-color);
  border-radius: 20px;
  padding: 10px;
}

.wp-60 {
  width: 60%;
}

.wp-20 {
  width: 20%;
}

.wp-50 {
  width: 50%;
}

.resp {
  width: 100%;
  height: auto;
}

.vresp {
  height: 100%;
  width: auto;
}

.vs {
  height: 100%;
  overflow: auto;
}

.mw-300 {
  max-width: 300px;
}
.mw-20 {
  max-width: 20%;
}
.mw-30 {
  max-width: 30%;
}
.mw-33 {
  max-width: 33%;
}
.mw-40 {
  max-width: 40%;
}

.w100 {
  width: 100%;
}

.w75 {
  width: 75%;
}

.w66 {
  width: 66%;
}

.w50 {
  width: 50%;
}
.w40 {
  width: 40%;
}

.w33 {
  width: 33%;
}
.w30 {
  width: 30%;
}

.w25 {
  width: 25%;
}

.w20 {
  width: 20%;
}

.w10 {
  width: 10%;
}

.h100 {
  height: 100%;
}

.r10 {
  border-radius: 10px;
}

.r20 {
  border-radius: 20px;
}

.r30 {
  border-radius: 30px;
}

.p5 {
  padding: 5px;
}

.p10 {
  padding: 10px;
}

.p15 {
  padding: 15px;
}

.p20 {
  padding: 20px;
}
.p30 {
  padding: 20px;
}

.m5 {
  margin: 5px;
}
.m0 {
  margin: 0px;
}

.m10 {
  margin: 10px;
}

.relative {
  position: relative;
}

.pointer {
  cursor: pointer;
}

.h100-segment {
  height: calc(100% - 55px);
}

.sm-h100-segment {
  height: 100%;
}

.section-fh-hidden {
  position: relative;
  height: 100%;
  overflow: hidden;
}

.section-fh {
  overflow: auto;
  position: relative;
  height: 100%;
}

.section-fh-border {
  overflow: auto;
  position: relative;
  border-right: 1px solid var(--arii-dark-blue-color);
  height: 100%;
}

.section-fh-border-left {
  border-left: 1px solid #efefef;
}

.section-fh-border-visible {
  position: relative;
  border-right: 1px solid #efefef;
  height: 100%;
  overflow: visible;
}

.collapse-companion {
  height: 100%;
  overflow: auto;
}

.lg-avatar {
  width: 125px;
  height: 125px;
  font-size: 45px;
  margin-bottom: 10px;
}
.el-center {
  margin: 0 auto;
}
.img-center {
  display: block;
  margin: 0 auto;
}

.txt-uppercase {
  text-transform: uppercase;
}

.txt-left {
  text-align: left;
}

.txt-center {
  text-align: center;
}

.txt-right {
  text-align: right;
}

.txt-dark {
  color: #000;
}

.txt-primary {
  color: #007cdc;
}

.txt-danger {
  color: #eb445a;
}

.txt-warning {
  color: #e6b219;
}

.txt-white {
  color: #ffffff;
}

.txt-white-bg {
  color: #ffffff;
}

.txt-grey {
  color: #555555;
}

.txt-strike {
  text-decoration: line-through;
}

.txt-large {
  font-size: 25px;
}

.txt-x-large {
  font-size: 40px;
}

.txt-small {
  font-size: 12px;
}

.txt-bold {
  font-weight: bold;
}

.no-padding {
  padding: 0;
}

.no-margin {
  margin: 0;
}

.no-border {
  border: none;
}

.drop-cap-med {
  font-size: 30px;
  width: 60px;
  height: 60px;
  border-radius: 50%;
  line-height: 60px;
  background-color: #333;
  color: #fff;
  text-align: center;
}

.drop-cap-large {
  font-size: 30px;
  width: 80px;
  height: 80px;
  border-radius: 50%;
  line-height: 80px;
  background-color: #333;
  color: #fff;
  text-align: center;
}

.panel {
  border: 1px solid #d0d0d0;
  padding: 8px;
  margin-bottom: 10px;
  //background-color: #efefef
}

.panel-spaced {
  border: 1px solid #d0d0d0;
  padding: 8px;
  margin-bottom: 40px;
}

.panel-dashed {
  border: 1px dashed #d0d0d0;
  padding: 8px;
  margin-bottom: 10px;
}

.panel-primary {
  background-color: #337ab7;
  color: #fff;
  padding: 5px;
}

.panel-light {
  background-color: #fff;
  padding: 15px;
}

.panel-float-bottom {
  position: absolute;
  z-index: 9;
  bottom: 0px;
  left: 0px;
  right: 0px;
  background-color: #333;
  color: #fff;
}

.panel-fh-overlay-hide {
  position: absolute;
  background-color: #fff;
  z-index: 15;
  display: none;
  height: 100%;
  border-right: 2px solid #333;
}

.panel-form-row-top {
  background-color: #666;
}

.panel-form-row {
  background-color: #efefef;
  padding: 20px;
  border: 1px dashed #666;
  border-top: none;
}

.panel-form-col-top {
  background-color: #dcdcdc;
  border: 1px solid #666;
  border-bottom: none;
}

.panel-form-col {
  background-color: #f9f9f9;
  padding: 20px;
  border: 1px solid #666;
  border-top: none;
}

.panel-form-obj-top {
  background-color: #fff;
  border: 1px solid #999;
  border-bottom: none;
}

.panel-form-obj {
  background-color: #fff;
  padding: 20px;
  border: 1px solid #999;
  border-top: none;
}

.screen-shade {
  width: 100%;
  height: 100%;
  position: fixed;
  z-index: 100;
  left: 0;
  top: 0;
  background-color: rgba(0, 0, 0, 0.5);
}

#lockScreen {
  width: 100%;
  height: 100%;
  position: fixed;
  z-index: 105;
  left: 0;
  top: 0;
  background-color: rgba(0, 0, 0, 0.5);
}

#lockScreen .lockContent {
  width: 500px;
  margin: 0 auto;
  margin-top: 50px;
  z-index: 110;
  background-color: #fff;
  padding: 20px;
  border-radius: 20px;
}

.closeModal {
  border-left: 1px solid #555;
  border-bottom: 1px solid #555;
  font-size: 18px;
  font-weight: bold;
  width: 60px;
  height: 45px;
  position: absolute;
  right: 0;
  top: 0;
  z-index: 120;
  border-radius: 0 0px 0 30px;
  text-align: center;
  line-height: 50px;
  background-color: #555;
  color: #fff;
  cursor: pointer;
}

.closeModal:hover {
  background-color: #333;
}

#modal {
  width: 100%;
  height: 100%;
  position: fixed;
  z-index: 100;
  left: 0;
  top: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
}

#modal .modalContent {
  position: relative;
  z-index: 101;
  padding: 20px;
  padding-top: 50px;
  overflow: auto;
  border-radius: 20px;
  border: 1px solid #555;
  color: #000;
  max-height: 95%;
  max-width: 1042px;
  width: 100%;
  height: 98%;
}

#miniModal {
  width: 100%;
  height: 100%;
  position: fixed;
  z-index: 105;
  left: 0;
  top: 0;
  background-color: rgba(0, 0, 0, 0.5);
}

#miniModal .modalContent {
  top: 25%;
  left: 20%;
  right: 20%;
  bottom: 25%;
  position: absolute;
  z-index: 110;
  padding: 20px;
  overflow: auto;
  border-radius: 20px;
  border: 1px solid #555;
  color: #000;
}

#microModal {
  width: 100%;
  height: 100%;
  position: fixed;
  z-index: 125;
  left: 0;
  top: 0;
  background-color: rgba(0, 0, 0, 0.5);
  color: #000;
}

#microModal .modalContent {
  top: 30%;
  left: 30%;
  right: 30%;
  bottom: 30%;
  position: absolute;
  z-index: 130;
  padding: 20px;
  overflow: auto;
  border-radius: 20px;
  border: 1px solid #555;
}

/*pin pad*/
.pinPad {
  width: 100%;
}

.pinPad td {
  width: 33%;
  border: 1px solid #b8b8b8;
  background-color: #fdfdfd;
}

.pinPad button {
  width: 100%;
  height: 80px;
  text-align: center;
  vertical-align: middle;
  color: #000;
  font-size: 30px;
}

.pinPad button:active {
  background-color: #c0c3c7;
}

.pinInput {
  width: 100%;
  border: none;
  height: 50px;
  font-size: 30px;
  text-align: center;
  background-color: #efefef;
}

.bubble {
  background-color: #f2f2f2;
  border-radius: 5px;
  box-shadow: 0 0 6px #b2b2b2;
  display: inline-block;
  padding: 10px 18px;
  position: relative;
  vertical-align: top;
}

.bubble::before {
  background-color: #f2f2f2;
  content: "\00a0";
  display: block;
  height: 16px;
  position: absolute;
  top: 11px;
  transform: rotate(29deg) skew(-35deg);
  -moz-transform: rotate(29deg) skew(-35deg);
  -ms-transform: rotate(29deg) skew(-35deg);
  -o-transform: rotate(29deg) skew(-35deg);
  -webkit-transform: rotate(29deg) skew(-35deg);
  width: 20px;
}

.b-right {
  float: right;
  margin: 5px 20px 5px 45px;
  background-color: #dcf8c6;
}

.b-right::before {
  box-shadow: 2px -2px 2px 0 rgba(178, 178, 178, 0.4);
  right: -9px;
  background-color: #dcf8c6;
}

.b-left {
  float: left;
  margin: 5px 45px 5px 20px;
}

.b-left::before {
  box-shadow: -2px 2px 2px 0 rgba(178, 178, 178, 0.4);
  left: -9px;
}

.b-bottom {
  float: left;
  margin: 0px 45px 5px 0px;
}

.b-bottom::before {
  box-shadow: 2px 2px 2px 0 rgba(178, 178, 178, 0.4);
  top: calc(100% - 9px);
}

.b-top {
  float: left;
  margin: 0px 45px 5px 0px;
}

.b-top::before {
  box-shadow: -2px -2px 2px 0 rgba(178, 178, 178, 0.4);
  top: -9px;
}

.b-top-right {
  float: right;
  margin: 0px 15px 5px 0px;
}

.b-top-right::before {
  box-shadow: -2px -2px 2px 0 rgba(178, 178, 178, 0.4);
  top: -7px;
  right: 20px;
}

.tbl th {
  padding: 8px;
  font-size: 16px;
}

.tbl td {
  padding: 8px 5px 8px 5px;
  font-size: 16px;
}

.tbl tbody tr {
  border-top: 1px solid #dcdcdc;
}

.tbl-hover tr:hover {
  background-color: #efefef;
}

.searchbar-md .searchbar-input {
  background-color: #f4f4f4;
  border: 1px solid #f0f0f0;
  padding: 5px 10px 5px 10px;
  -webkit-box-shadow: none;
  box-shadow: none;
  font-size: inherit;
  line-height: inherit;
}

.searchbar-md .searchbar-search-icon {
  display: none;
}

// ion-item:hover {
//     background-color: #dcdcdc;
//     color: #000;
// }

.td-input {
  border: 1px solid #dcdcdc;
}

.ion-input {
  border: 1px solid #fff;
}

.ion-input ion-label {
  border-right: 3px double #007cdc;
  margin-right: 5px;
}

.ion-input .label-md {
  color: #555;
  font-size: 14px;
  font-weight: 600;
}

.ion-input .native-input {
  background-color: #f4f4f4;
  --background: #f4f4f4;
  border: 1px solid #f0f0f0;
  padding: 5px 10px 5px 10px;
  // --placeholder-font-weight: 300;
  // font-weight: 400;
}

.ion-input ion-select {
  background-color: #f4f4f4;
  background: #f4f4f4;
  border: 1px solid #f0f0f0;
  padding: 5px 10px 5px 10px;
  width: 100%;
  margin: 0px;
}

.ion-stacked {
  border: 1px solid #fff;
}

.ion-stacked ion-label {
  min-height: 35px;
}

.ion-stacked .label-md {
  //color: #888 !important;
  font-size: 13px;
  font-weight: 600;
}

.ion-stacked .native-input {
  // background-color: #f4f4f4;
  // --background: #f4f4f4;
  // border: 1px solid #f0f0f0;
  padding: 10px;
  // --placeholder-font-weight: 300;
  // font-weight: 400;
}

.ion-stacked ion-select {
  background-color: var(--arii-dark-blue-color);
  border-radius: 50px;
  font-family: "Proxima Nova";
  color: white;
  padding: 5px 10px 5px 10px;
  width: 100%;
  margin: 0px;
}

.item-md.item-input.ng-valid.item-input-has-value:not(.input-has-focus):not(.item-input-has-focus) .item-inner,
.item-md.item-input.ng-valid.input-has-value:not(.input-has-focus):not(.item-input-has-focus) .item-inner,
.list-md .item-input.ng-valid.item-input-has-value:not(.input-has-focus):not(.item-input-has-focus):last-child,
.list-md .item-input.ng-valid.input-has-value:not(.input-has-focus):not(.item-input-has-focus):last-child {
  border-bottom-color: rgb(222, 222, 222);
  /*border-bottom-color: transparent;*/
  /*border-bottom: none;*/
  box-shadow: none;
}

/*.ion-input {
  border: 1px solid #dcdcdc;
  border-radius: 5px;
  margin-bottom: 3px;
}*/

.lg-collapse-companion {
  width: 100%;
  max-width: 100%;
  min-width: 100%;
  position: absolute;
  z-index: 3;
  background-color: #fff;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  padding: 5px;
}

.dark-hide {
  display: block;
}
.dark-show {
  display: none;
}
.light-hide {
  display: none;
}
.light-show {
  display: block;
}

//START DARK THEME DARK
//@media (prefers-color-scheme: dark) {
body.dark {
  .dark-hide {
    display: none;
  }

  .dark-show {
    display: block;
  }

  .light-hide {
    display: block;
  }

  .light-show {
    display: none;
  }

  .txt-white-bg {
    color: #000;
  }

  .bubble {
    background-color: #222;
    box-shadow: 0 0 6px #b2b2b2;
  }

  .bubble::before {
    background-color: #222;
  }

  .panel {
    border: 1px solid #888;
    background-color: #222;
    padding: 8px;
    margin-bottom: 10px;
  }

  .panel-spaced {
    border: 1px solid #444;
  }

  .section-fh-border {
    border-right: 1px solid #333;
  }

  .logo {
    -webkit-filter: invert(1) hue-rotate(180deg);
    filter: invert(1) hue-rotate(180deg);
  }

  .img-ui {
    -webkit-filter: invert(1) hue-rotate(180deg);
    filter: invert(1) hue-rotate(180deg);
  }

  // ion-item:hover {
  //     --background: #333;
  //     background: #333;
  //     color: #fff;
  // }

  .ion-input ion-label {
    border-right: 3px double #007cdc;
    margin-right: 5px;
  }

  .ion-input .label-md {
    color: #555;
    font-size: 14px;
    font-weight: 600;
  }

  .ion-input .native-input {
    background-color: #444;
    --background: #444;
    border: 1px solid #888;
    padding: 5px 10px 5px 10px;
    // --placeholder-font-weight: 300;
    // font-weight: 400;
  }

  .ion-input ion-select {
    --background-color: #444;
    background-color: #444;
    --background: #444;
    background: #444;
    border: 1px solid #888;
  }

  .profile-members-container {
    background-color: #444444;
  }

  .profile-members:hover {
    background-color: #666666;
  }
}
//}
//END DARK THEME DARK

/*START ANIMATIONS*/
.animated {
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}

.animated-fast {
  -webkit-animation-duration: 0.4s;
  animation-duration: 0.4s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}
/*END ANIMATIONS*/
.btn-upload {
  //background: #efefef;
  padding: 5px;
}

.btn-upload::file-selector-button {
  color: dodgerblue;
  padding: 5px;
  border: thin solid grey;
  border-radius: 10px;
}

.btn-selected-white {
  background-color: #007cdc;
  color: #fff;
  --ion-color-base: #fff;
}

.btn-header {
  border-radius: 30px;
  background-color: var(--ion-color-primary, #3880ff);
  color: #fff;
  --ion-color-base: #fff;
}

/*start dark segment*/
.segment-container {
  background-color: #222;
  margin-top: 10px;
  margin-bottom: 10px;
}

.segment-container-button {
  width: 50%;
  margin: 0px;
}

.selected {
  --background: #007cdc !important;
  background: #007cdc !important;
  color: #fff;
}

.selected > div > ion-label {
  color: #fff;
}

.selected-gradient {
  --background: linear-gradient(90deg, rgba(83, 194, 219, 1) 0%, rgba(109, 28, 221, 1) 100%) !important;
  background: linear-gradient(90deg, rgba(83, 194, 219, 1) 0%, rgba(109, 28, 221, 1) 100%) !important;
  color: #fff;
}

.selected:hover {
  --background: #0061af;
  background: #0061af;
  color: #fff;
}

.selected-round {
  --background: linear-gradient(90deg, rgba(83, 194, 219, 1) 0%, rgba(109, 28, 221, 1) 100%) !important;
  background: linear-gradient(90deg, rgba(83, 194, 219, 1) 0%, rgba(109, 28, 221, 1) 100%) !important;
  color: #fff;
  border-radius: 15px;
}

.selected-round:hover {
  --background: #0061af;
  background: #0061af;
  color: #fff;
}

.selected-white {
  background-color: #007cdc;
  --background-color: #007cdc;
  color: #fff;
  --ion-color-base: #fff;
}

.selected-white:hover {
  background-color: #0061af;
  --background-color: #0061af;
  color: #fff;
  --ion-color-base: #fff;
}

.selected-border {
  border-bottom: 3px solid #007cdc;
}

.selected-border-light {
  border-bottom: 3px solid #dcdcdc;
}

.disabled {
  opacity: 0.4;
}

.disabled:hover {
  opacity: 0.5;
}

.segment-button:hover {
  background-color: #dcdcdc;
}

.es-segment {
  background-color: #000;
  color: #fff;
  overflow-x: auto;
  border-radius: 5px 5px 0px 0px;
  padding: 0 10px 0 10px;
}

.dark-pill {
  background-color: #000;
  color: #fff;
  border-radius: 30px;
  min-width: 50px;
  max-width: 150px;
}

/*.dark-pill:hover {
    background-color: #444;
}

.dark-segment {
    background-color: #000;
    color: #fff;
    overflow-x: auto;
    border-radius: 5px 5px 0px 0px;
    padding: 0 10px 0 10px;
}

.dark-segment ion-segment-button:hover {
    background-color: #444;
}

.dark-segment ion-segment-button.segment-button-checked:hover {
    background-color: #fff;
}*/

.segment-fix {
  justify-content: flex-start;
  width: 100%;
  text-align: center;
}

/*.dark-segment .segment-button-layout-icon-top {
    color: #fff;
}

.dark-segment .segment-button-checked {
    color: #000;
    background-color: #fff;
    --background-color: #fff;
    border: 2px solid #000;
    border-bottom: 2px solid #fff;
    border-radius: 5px 5px 0 0;
}*/

.light-segment {
  background-color: #888;
  color: #000;
  border-bottom: 2px solid #fff;
  overflow-x: auto;
}

.light-segment .segment-button-layout-icon-top {
  color: #fff;
}

.light-segment .segment-button-checked {
  color: #000;
  background-color: #fff;
  --background-color: #fff;
  border: 2px solid #dcdcdc;
  border-bottom: 2px solid #fff;
  border-radius: 5px 5px 0 0;
}

/*end dark segment*/

/*Profile elements*/
.profile {
  position: relative;
  background-color: #333;
  min-height: 150px;
}

.profile .edit {
  position: absolute;
  top: 0;
  right: 0;
  color: #fff;
  background-color: rgba(0, 0, 0, 0.3);
}

.profile .sysinfo {
  background-color: rgba(0, 0, 0, 0.7);
  padding: 5px;
  position: absolute;
  bottom: 0px;
  font-size: 12px;
  color: #fff;
  width: 100%;
  font-weight: bold;
}

.profile-members {
  width: 50%;
  display: inline-block;
  vertical-align: top;
  cursor: pointer;
  padding: 2px;
}

.profile-members:hover {
  background-color: #dcdcdc;
  color: #fff;
  cursor: pointer;
}

.profile-members-container {
  padding-top: 5px;
  padding-bottom: 5px;
  background-color: #efefef;
}

.profile-members-selected {
  background-color: #fff;
  color: #000;
}

.profile-members-hide {
  display: none;
}

/*table Element*/

.tbl-pinpad > thead {
  border-bottom: 20px solid #fff;
}

.tbl-pinpad > tfoot {
  border-top: 20px solid #fff;
}

.tbl-striped tbody tr:nth-child(even) {
  background: #efefef;
}

.tbl-striped td {
  padding: 5px;
}

.tbl-striped thead {
  color: #fff;
  background: #333;
}
/* End table element */

/* Overrides */
ion-select {
  max-width: 100%;
  margin: 13px 8px;
}
/* End Overrides */

/*
xs 0 size-
Set columns when (min-width: 0)

sm 576px size-sm-
Set columns when (min-width: 576px)


md 768px size-md-
Set columns when (min-width: 768px)

lg 992px size-lg-
Set columns when (min-width: 992px)

xl 1200px size-xl-
Set columns when (min-width: 1200px)
*/

/* LG SCREEN OVERRIDES */
@media (min-width: 992px) {
  /*make ion-input labels wider*/
  .label-fixed.sc-ion-label-md-h {
    min-width: 140px;
    max-width: 300px;
  }

  /*make confirmation and alert boxes wider*/
  .alert-wrapper.sc-ion-alert-md {
    min-width: 400px;
    max-width: 600px;
    border-radius: 20px;
    border: 1px solid var(--arii-dark-blue-color);
  }

  .alert-head.sc-ion-alert-md h2 {
    color: var(--arii-purple-color);
    font-size: 28px;
  }

  .alert-message.sc-ion-alert-md {
    font-family: "Proxima Nova";
    line-height: 1.2;
    font-size: 16px;
  }

  .lg-hide {
    display: none;
  }

  .lg-show {
    display: block;
  }

  .header {
    display: none;
  }

  .scroll-content {
    margin-top: 0px;
  }
}

/* MID & SMALL SCREEN OVERRIDES */
@media (max-width: 992px) {
  .lg-show {
    display: none;
  }

  .lg-hide {
    display: block;
  }
}

@media screen and (max-width: 767px) {
  /*if mobile screen*/
  .sm-show {
    display: block;
  }

  .sm-hide {
    display: none;
  }

  .sm-h100-segment {
    height: calc(100% - 55px);
  }

  .section-fh-border {
    border-right: 0px;
  }

  body.dark {
    .section-fh-border {
      border-right: 0px;
    }
  }

  .patientSearch {
    position: absolute;
    left: 0;
    width: 100%;
    top: 10px;
    z-index: 5;
  }

  .col-sm-hide {
    height: 100%;
    width: 100%;
    max-width: 100%;
    position: absolute;
    z-index: 10;
    background: #fff;
    display: none;
  }

  table .collapse > td {
    width: 100%;
    display: block;
  }

  .printable-wrapper {
    transform: scale(0.5);
  }

  .collapse {
    width: 100%;
    max-width: 100%;
    min-width: 100%;
  }

  .collapse-companion {
    width: 100%;
    max-width: 100%;
    min-width: 100%;
    position: absolute;
    z-index: 10;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    padding: 5px;
  }

  .collapse-companion-show {
    width: 100%;
    max-width: 100%;
    min-width: 100%;
    position: absolute;
    z-index: 10;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    padding: 5px;
    display: block;
    background: #fff;
  }

  .mobile-header {
    margin-bottom: 10px;
  }

  #miniModal .modalContent {
    top: 10%;
    left: 1%;
    right: 1%;
    bottom: 10%;
    padding-top: 50px;
  }

  #modal .modalContent {
    top: 8%;
    left: 1%;
    right: 1%;
    bottom: 1%;
    position: absolute;
    z-index: 101;
    padding-top: 50px;
    overflow: auto;
    border-radius: 20px;
    height: 85%;
  }

  #lockScreen {
    width: 100%;
    height: 100%;
    position: fixed;
    z-index: 105;
    left: 0;
    top: 0;
    background-color: rgba(0, 0, 0, 0.5);
  }

  #lockScreen .lockContent {
    width: 100%;
    margin: 0 auto;
    margin-top: 50px;
    z-index: 110;
    background-color: #fff;
    padding: 20px;
    border-radius: 20px;
  }
}

/* ANIMATIONS */

.animated {
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}

.animated-show {
  display: block;
  -webkit-animation-duration: 0.3s;
  animation-duration: 0.3s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  z-index: 10;
}

@-webkit-keyframes fadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

.fadeIn {
  -webkit-animation-name: fadeIn;
  animation-name: fadeIn;
}

@-webkit-keyframes fadeInDown {
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, -100%, 0);
    transform: translate3d(0, -100%, 0);
  }

  to {
    opacity: 1;
    -webkit-transform: none;
    transform: none;
  }
}

@keyframes fadeInUp {
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, 100%, 0);
    transform: translate3d(0, 100%, 0);
  }

  to {
    opacity: 1;
    -webkit-transform: none;
    transform: none;
  }
}

.fadeInUp {
  -webkit-animation-name: fadeInUp;
  animation-name: fadeInUp;
}

@-webkit-keyframes fadeInUp {
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, 100%, 0);
    transform: translate3d(0, 100%, 0);
  }

  to {
    opacity: 1;
    -webkit-transform: none;
    transform: none;
  }
}

@keyframes fadeInUp {
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, 100%, 0);
    transform: translate3d(0, 100%, 0);
  }

  to {
    opacity: 1;
    -webkit-transform: none;
    transform: none;
  }
}

.fadeInUp {
  -webkit-animation-name: fadeInUp;
  animation-name: fadeInUp;
}

@-webkit-keyframes fadeInLeft {
  from {
    opacity: 0;
    -webkit-transform: translate3d(-100%, 0, 0);
    transform: translate3d(-100%, 0, 0);
  }

  to {
    opacity: 1;
    -webkit-transform: none;
    transform: none;
  }
}

@keyframes fadeInLeft {
  from {
    opacity: 0;
    -webkit-transform: translate3d(-100%, 0, 0);
    transform: translate3d(-100%, 0, 0);
  }

  to {
    opacity: 1;
    -webkit-transform: none;
    transform: none;
  }
}

.fadeInLeft {
  -webkit-animation-name: fadeInLeft;
  animation-name: fadeInLeft;
}

@-webkit-keyframes fadeInRight {
  from {
    opacity: 0;
    -webkit-transform: translate3d(100%, 0, 0);
    transform: translate3d(100%, 0, 0);
  }

  to {
    opacity: 1;
    -webkit-transform: none;
    transform: none;
  }
}

@keyframes fadeInRight {
  from {
    opacity: 0;
    -webkit-transform: translate3d(100%, 0, 0);
    transform: translate3d(100%, 0, 0);
  }

  to {
    opacity: 1;
    -webkit-transform: none;
    transform: none;
  }
}

.fadeInRight {
  -webkit-animation-name: fadeInRight;
  animation-name: fadeInRight;
}

@-webkit-keyframes slideInDown {
  from {
    -webkit-transform: translate3d(0, -100%, 0);
    transform: translate3d(0, -100%, 0);
    visibility: visible;
  }

  to {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}

@keyframes slideInDown {
  from {
    -webkit-transform: translate3d(0, -100%, 0);
    transform: translate3d(0, -100%, 0);
    visibility: visible;
  }

  to {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}

.slideInDown {
  -webkit-animation-name: slideInDown;
  animation-name: slideInDown;
}

@-webkit-keyframes slideInLeft {
  from {
    -webkit-transform: translate3d(-100%, 0, 0);
    transform: translate3d(-100%, 0, 0);
    visibility: visible;
  }

  to {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}

@keyframes slideInLeft {
  from {
    -webkit-transform: translate3d(-100%, 0, 0);
    transform: translate3d(-100%, 0, 0);
    visibility: visible;
  }

  to {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}

.slideInLeft {
  -webkit-animation-name: slideInLeft;
  animation-name: slideInLeft;
}

@-webkit-keyframes slideInRight {
  from {
    -webkit-transform: translate3d(100%, 0, 0);
    transform: translate3d(100%, 0, 0);
    visibility: visible;
  }

  to {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}

@keyframes slideInRight {
  from {
    -webkit-transform: translate3d(100%, 0, 0);
    transform: translate3d(100%, 0, 0);
    visibility: visible;
  }

  to {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}

.slideInRight {
  -webkit-animation-name: slideInRight;
  animation-name: slideInRight;
}

@-webkit-keyframes slideInUp {
  from {
    -webkit-transform: translate3d(0, 100%, 0);
    transform: translate3d(0, 100%, 0);
    visibility: visible;
  }

  to {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}

@keyframes slideInUp {
  from {
    -webkit-transform: translate3d(0, 100%, 0);
    transform: translate3d(0, 100%, 0);
    visibility: visible;
  }

  to {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}

.slideInUp {
  -webkit-animation-name: slideInUp;
  animation-name: slideInUp;
}

@-webkit-keyframes slideOutDown {
  from {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }

  to {
    visibility: hidden;
    -webkit-transform: translate3d(0, 100%, 0);
    transform: translate3d(0, 100%, 0);
  }
}

@keyframes colorIn {
  from {
    background-color: #337ab7;
  }

  to {
    background-color: #fff;
  }
}

.colorIn {
  -webkit-animation-name: colorIn;
  animation-name: colorIn;
}

@keyframes blinker {
  50% {
    background-color: rgb(255, 0, 0);
  }
}

.flashing {
  //--background: #dc0000 !important;
  //background: #dc0000 !important;
  //color: #fff;
  -webkit-animation: blinker 1s linear infinite; /* Safari 4+ */
  -moz-animation: blinker 1s linear infinite; /* Fx 5+ */
  -o-animation: blinker 1s linear infinite; /* Opera 12+ */
  animation: blinker 1s linear infinite; /* IE 10+, Fx 29+ */
}

.activeCall {
  background: #1b9648 !important;
  --background: #1b9648 !important;
}
